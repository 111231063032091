// Generated by CoffeeScript 1.6.3
/*
file input plugin
*/
(function() {
  (function($, window, document) {
    var FileInputReplacer, pluginName;
    pluginName = "fileinput";
    FileInputReplacer = (function() {
      function FileInputReplacer(elm) {
        var label;
        this.elm = $(elm);
        this.container = $('<label class="fileinput-wrapper"/>');
        if (this.elm.attr('id')) {
          label = this.elm.closest('label#' + this.elm.attr('id'));
          if (label.length) {
            this.container = label;
            this.container.addClass('fileinput-wrapper');
          } else {
            this.container.insertBefore(this.elm).append(this.elm);
          }
        } else {
          this.container.insertBefore(this.elm).append(this.elm);
        }
        this.container.prepend($("<span class=\"button file\">" + (this.elm.data('button-name')) + "</span>"));
      }

      return FileInputReplacer;

    })();
    return $.fn[pluginName] = function() {
      return this.each(function() {
        if (!$.data(this, "plugin_" + pluginName)) {
          return $.data(this, "plugin_" + pluginName, new FileInputReplacer(this));
        }
      });
    };
  })(jQuery, window, document);

}).call(this);