const link = document.getElementById("online-servis")
const close = document.getElementById('online-servis-close')
const checkbox = document.getElementById('online-servis-checkbox')

function handleCheckbox() {
    const container = link.closest('.messages')

    if (checkbox.checked) {
        container.classList.add('is-open')
    } else {
        container.classList.remove('is-open')
    }
}
if (link && checkbox) {
    link.addEventListener('click', function(event){
        event.preventDefault()
        checkbox.checked = true
        handleCheckbox()
    });
}

if (close && checkbox) {
    close.addEventListener('click', function(event) {
        event.preventDefault()
        checkbox.checked = false
        handleCheckbox()
    })
}