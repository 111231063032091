// set basepath of project for webpack
__webpack_public_path__ = `/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import 'jquery.easing'
import './plugins/dropkick.js'
import './plugins/fileinput.js'
import './plugins/icheck.js'
import './plugins/placeholder.js'
import './plugins/xeyes.js'
import './toggleFunction.js'

import './main.js'
