(function (window, document, $, undefined) {

	var capitalize = function (string) {
		return (string + '').replace(/\b[a-z]/g, function (match) {
			return match.toUpperCase()
		});
	}

	var contractTemplate = $('.contracts div.contract:first-child');

	var Site = {
		init: function () {
			$('.preopened').removeClass('preopened');
			Site.Form.init();

			// Giant Logo
			$('#giant img').css('cursor', 'pointer').on('click', function (event) {
				window.open($('#giant a').attr('href'));
			});

			Site.Felix.init();
			Site.Contact.init();
			Site.DateInput.init();
		},

		setup: function () {
			$(window).trigger('layoutChange');
		}
	};

	Site.DateInput = {
		init: function () {

			var stopBlur = false;

			function replaceFirstNumber($number, $list) {
				$("a.ynumber", $list).each(function () {
					var $oldVal = $(this).html();
					var $newVal = "&rsquo;" + $number + $oldVal.substr(2);
					$(this).html($newVal);
				})
			}

			$('input[data-list]')
				.on('click focus', function (e) {
					e.preventDefault();

					var input, name, list, _this;
					input = $(this);
					name = $(this).data('list');
					list = $('#' + name);

					list.removeClass('hidden').css({
						opacity: '',
						top: input.position().top + input.outerHeight() + 10
					}).fadeIn();

					$("a", list).off('click').on('click', function (e) {
						e.preventDefault();

						if (name === 'year-day' && !$(this).hasClass('ynumber')) {
							input.val($(this).text());
							$("a", list).hide();

							var decade = input.val().substr(2, 1);
							replaceFirstNumber(decade, list);

							$("a.ynumber", list).fadeIn();
							stopBlur = true;

						} else if (name === 'year-day' && $(this).hasClass('ynumber')) {
							stopBlur = false;
							input.val(parseInt(input.val()) + parseInt($(this).text().substr(2)));
							list.fadeOut(400, function () {
								list.addClass('hidden').css({
									display: 'none',
									opacity: 0
								});
								$("a", list).show();
								$("a.ynumber", list).hide();
							});
							// index = input.closest('form').find('input').index(input) + 1;
							// input.closest('form').find('input').eq(index).focus();
						} else {
							input.val($(this).text());
						}
					});
				})
				.on('blur', function () {
					_this = this;
					window.setTimeout(function () {
						if (stopBlur === true) {
							stopBlur = false;
						} else {
							var input, name, list;
							input = $(_this);
							name = input.data('list');
							list = $('#' + name);
							list.fadeOut();
						}
					}, 200);
				});
		}
	};

	Site.Form = {
		backSubmiter: false,

		init: function () {
			$('form').each(function (index, form) {
				if (form.id) {
					var objectId = capitalize($.camelCase(form.id.replace('form-', '')));
					if (objectId in Site.Form) {
						Site.Form[objectId].init(form);
					}
				}
			});

			$(document).find('#form-contact').append('<input type="hidden" name="anti" value="rsts"/>');

			var _this = this;

			// form validation
			$(document).on('submit', 'form.validate', $.proxy(this.validate, this));
			$(document).on('click', 'form button.back', function (e) {
				_this.backSubmiter = true;
			});

			//selectobex
			$('select').dropkick();
			// $('select').fancySelect();

			$('form fieldset.simple .title.label-title')
				.on('mouseenter', function () {
					var selector = $(this);
					// if (!selector.closest('fieldset.simple').hasClass('opened')) {
					selector.addClass('hover')
					// }
				})
				.on('mouseleave', function () {
					$(this).removeClass('hover')
				});

			// custom radio
			$('input[type=radio], input[type=checkbox]').iCheck();

			// custom file input
			$('input[type="file"]').fileinput();

			// open/close parts of forms
			$('.parted-form fieldset.simple .label').on('click', this.handleOpenCloseParts);


			$("input[name=formType]:radio").on('ifClicked', function () {
				var val = parseInt($(this).val());
				if (val === 1) {
					$('.contracts').addClass('selected');
					$('.simple-address').removeClass('selected');
				} else {
					$('.simple-address').addClass('selected');
					$('.contracts').removeClass('selected');
				}
			});


			// filename
			$('.fileinput').on('change', function (event) {
				var filename = event.target.value.split('\\').pop();
				var label = $(this).closest('.fileinput').find('.selected-file-name')
				var link = $(this).closest('.fileinput').find('a')

				link.text(filename)
				link.attr("href", URL.createObjectURL(event.target.files[0]))
				link.css('display', 'block')

				if (link.text) {
					label.text('');
					label.css('display', 'none')

					$(this).closest('.fileinput').find('.uploaded-file').css('display', 'none')
					$(this).closest('.fileinput').find('.error').css('display', 'none')

					$('#contact-change-form').find('.errors').remove();
				}
			});

			$(document).on('click', '.contracts .button.add', this.createContract);
			$(document).on('click', '.contracts .button.trash', this.removeContract);

			// placeholders
			$('input[placeholder],textarea[placeholder]').placeholder();

			// zmenaTrvalaAdresa
			this.zmenaTrvalaAdresa = document.getElementById('zmena-trvala-adresa')
			this.zmenaTrvalaAdresaChanged = false
			var inputsArray = []
			this.allInputsWithValues = false

			if (this.zmenaTrvalaAdresa) {
				this.zmenaTrvalaAdresa.addEventListener('change', function (event) {
					$("#zmena-trvala-adresa input").each(
						function (index, el) {
							const obj = {
								name: el.name,
								value: el.value
							}
							inputsArray.push(obj)

						}
					);
					this.allInputsWithValues = inputsArray.every(input => {
						return input.value.length > 0
					})

					if (this.allInputsWithValues) {
						this.zmenaTrvalaAdresaChanged = true
					}
				})
			}

			// agreementModal
			this.agreementModal = document.getElementById('agreement-modal')
			this.agreementYesButton = document.getElementById('agreement-modal-yes')
			this.agreementNoButton = document.getElementById('agreement-modal-no')

			if (this.agreementYesButton) {
				this.agreementYesButton.addEventListener('click', function (event) {
					event.preventDefault()
					this.agreementModal.classList.remove('is-active')
					document.getElementById("uploadFiles-step").classList.remove('opened')

					var $step = $('#agreement-step')

					if (!$step.hasClass('opened')) {
						$('#agreement-step .label').click()
					}
					$('html, body').animate({
						scrollTop: $("#agreement-step").offset().top - 100
					}, 510, 'easeOutQuad');
				}.bind(this))
			}

			if (this.agreementNoButton) {
				this.agreementNoButton.addEventListener('click', function (event) {
					event.preventDefault()
					this.agreementModal.classList.remove('is-active')
					document.getElementById("uploadFiles-step").classList.remove('opened')
					document.getElementById('contact-change-form').submit()
				}.bind(this))
			}

			$('#form-agreement-personal-data').on('ifChecked', function (event) {
				$('#form-disagreement-personal-data').iCheck('uncheck')
			});
			$('#form-disagreement-personal-data').on('ifChecked', function (event) {
				$('#form-agreement-personal-data').iCheck('uncheck')
			})
		},

		validate: function (e) {
			if (this.backSubmiter) {
				this.backSubmiter = false;
				return true;
			}
			e.preventDefault();
			var form;

			form = $(e.currentTarget);

			var _self = this

			// //Form data
			var data = new FormData(form[0]);

			// //File data
			var file_data = form.find($('input[type="file"]'));

			if (file_data) {
				if (file_data[0] !== undefined) {
					var files = file_data[0].files
					for (var i = 0; i < files.length; i++) {
						data.append("files[]", files[i]);
					}
				}
			}

			jQuery.ajax({
				type: form.attr('method'),
				url: form.attr('action'),
				dataType: "json",
				data: data,
				contentType: false,
				cache: false,
				processData: false,
				success: function (response) {
					if (form.attr('id') === 'form-contact' && parseInt(response.result) === 1) {
						return Site.Contact.finish(form);
					}

					if (parseInt(response.result) === 1) {
						if (form.attr('id') === 'contact-change-form') {
							var agrement = document.getElementById('form-agreement-personal-data')
							if (agrement && !agrement.checked) {
								_self.agreementModal.classList.add('is-active')
								document.getElementById("uploadFiles-step").classList.remove('opened')
								return;
							}
						}
						return form[0].submit();
					}

					form.find('.errors').remove();
					form.find('.error').remove();
					form.find('.error-input').removeClass('error-input');

					var name, msg, blockErrors = [], block, list;

					for (name in response.error) {
						msg = response.error[name];
						element = form.find('input[name="item[' + name + ']"], input[name="' + name + '"], textarea[name="' + name + '"]');
						var normalElement = [];

						if (name === 'birthday') {
							var bmsg, bname, element;

							for (bname in msg) {
								bmsg = msg[bname];
								if (bname !== 'error') {
									$('input[name="item[' + bname + ']"]', form).addClass('error-input');
									error_msg = bmsg;
								}
							}

							element = $('.birthbox', form);
							var error_msg = msg['error'] || error_msg;
							console.log(error_msg);
							$('<div class="error">' + error_msg + '</div>').insertAfter(element);

						} else if (element.length) {
							element.addClass('error-input');
							$('<div class="error">' + msg + '</div>').insertAfter(element);
						} else if (normalElement.length) {
							normalElement.addClass('error-input');
							$('<div class="error">' + msg + '</div>').insertAfter(normalElement);
						} else if (msg !== '') {
							if (name == 'change_personal_data') {
								document.getElementById("zmena-osobni-udaje").classList.remove('opened')
								// $('#uploadFiles-step').addClass('opened')
								// $('#uploadFiles-step .anim-box').addClass('opened')
							}
							var isContract = name.split('-');
							if (isContract.length == 2) {
								var element = $('input[name="item[' + isContract[0] + '][' + isContract[1] + ']"]');
								if (element.length) {
									element.addClass('error-input');
									$('<div class="error">' + msg + '</div>').insertAfter(element);
								} else {
									blockErrors.push(msg);
								}
							} else {
								blockErrors.push(msg);
							}
						}
					}

					if (blockErrors.length) {
						block = $('<div class="errors"><ul/></div>');
						var x, _i, _len;

						for (_i = 0, _len = blockErrors.length; _i < _len; _i++) {
							x = blockErrors[_i];
							if (x.indexOf('občanského průkazu v záložce') > -1) {
								block.find('ul').append($('<li/>').text(x).attr('id', 'zmena-doklad-totoznosti'));

								const editedLi = block.find('#zmena-doklad-totoznosti').append('<a href="javascript:void(0)" style="color:inherit;"> ZMĚNIT DOKLAD TOTOŽNOSTI.</ >')
								editedLi.find('a').on('click', function (event) {
									event.preventDefault()
									document.getElementById("zmena-trvala-adresa").classList.remove('opened')
									$('#uploadFiles-step .label').click()
								});

							} else if (x.indexOf('Pro zdárné vyřízení přiložte ') > -1) {
								block.find('ul').append($('<li/>').text(x).attr('id', 'zmena-doklad-totoznosti'));

								const editedLi = block.find('#zmena-doklad-totoznosti').append('<a href="javascript:void(0)" style="color:inherit;"> ZMĚNIT DOKLAD TOTOŽNOSTI.</ >')
								editedLi.find('a').on('click', function (event) {
									event.preventDefault()
									$('#uploadFiles-step .label').click()
									$('html, body').animate({
										scrollTop: $("#uploadFiles-step").offset().top - 100
									}, 510, 'easeOutQuad');
								});

								var formContact = document.getElementById("contact-change-form")
								const errors = formContact.querySelectorAll('.error')

								if (errors.length) {
									block.empty()
									var ul = document.createElement("ul");
									var message = document.createElement("li");
									message.innerHTML = "Vyplňte, prosim, všechny povinné údaje.";
									ul.append(message)
									block.append(ul)
								}
							} else {
								block.find('ul').append($('<li/>').text(x));
							}
						}
						form.prepend(block);
					}

					if (form.attr('id') === 'form-contact') {
						Site.Contact.resizeHandler();
					} else if (form.attr('id') === 'contact-change-form') {
						form.find('.error-input').each(function () {
							var container = $(this).closest('fieldset.simple');
							var box = $('.anim-box', container);
							container.add(box).addClass('opened');
							box.css({
								height: ''
							})
						});
					}

					var sctop = 0;
					var firstErr = $(document).find('.error-input, .errors').eq(0);
					if (firstErr.length) {
						sctop = firstErr.offset().top - $('body>header').outerHeight();
					}
					$('html, body').animate({
						scrollTop: sctop
					}, 100);
				},
				error: function (xhr, ajaxOptions, thrownError) {
					// chybovka zmeny osobnich udaju
					const zmenaUdajeForm = document.getElementById("zmena-osobni-udaje")
					const inputsArray = Array.from(zmenaUdajeForm.querySelectorAll("input"))
					const filteredArray = inputsArray.filter(input => input.value.length > 0)

					filteredArray.forEach(item => {
						var block = $('<div class="errors"><ul><li class="item"></li></ul></div>');
						var errorMessage = '';
						if (item.name.indexOf('old') != -1) {
							errorMessage = 'Doplňte, prosím, nove jméno/příjmení'
						} else {
							errorMessage = 'Doplňte, prosím, evidované jméno/příjmení'
						}

						block.find('li').text(errorMessage);
						form.find('.errors').remove();
						form.prepend(block);
					})
				}
			});
		},

		handleOpenCloseParts: function (e) {
			var list, container, animBox, content;

			container = $(this).closest('fieldset.simple');
			animBox = container.find('.anim-box');
			content = animBox.find('.content');

			if (container.hasClass('opened')) {
				animBox.animate({
					height: 0
				}, 500, function () {
					container.removeClass('opened');
				});
				animBox.removeClass('opened');

			} else {
				animBox.animate({
					height: content.outerHeight() + 40
				}, 500, function () {
					animBox.css({ height: '' });
					animBox.addClass('opened');
				});
				container.addClass('opened');
			}
		},

		createContract: function (e) {
			e.preventDefault();

			var contracts, template, first, last, timeIndex;

			contracts = $('.contracts div.contract');
			first = contracts.first();
			last = contracts.last();


			if (contracts.length === 1 && !$('a.trash', first).length) {
				$('.title.removable', first).append('<a href="#" class="button active trash">X<span></span></a>');
			}

			template = contractTemplate.clone();
			$('.title.removable', template).append('<a href="#" class="button active trash">X<span></span></a>')

			timeIndex = new Date().getTime();

			template.find('input').each(function () {
				$(this).val('');
				var name = $(this).attr('name');
				$(this).attr('name', name.replace(/\[(\d+)\]$/g, '[' + timeIndex + ']'));
			});
			template.insertBefore($('.contracts .button.add'));
		},

		removeContract: function (e) {
			e.preventDefault();
			$(this).closest('div.contract').remove();
		}

	};

	Site.Felix = {
		init: function () {
			$(".eyeball").xeyes();
		}
	};

	Site.Contact = {
		init: function () {
			this.footer = $('main>footer');
			this.formBox = $('.contact-form', this.footer);
			this.formContent = $('.content', this.formBox);
			this.delim = $('main .mcontent .after');

			$('.contact-button').on('click', $.proxy(this.toggle, this));
			this.formBox.find('a.close').on('click', $.proxy(this.toggle, this));
			$(window).on('resize', $.proxy(this.resizeHandler, this));
			this.resizeHandler();
		},

		resizeHandler: function () {
			var fheight, fnheight, height;

			fheight = this.footer.outerHeight();
			height = 0;
			if (this.formBox.hasClass('visible') && !this.formBox.hasClass('animate')) {
				height = this.formContent.outerHeight() + 40;

				this.formBox.css({
					height: height
				});
			}

			this.footer.css({
				marginTop: -(fheight)
			});
			this.delim.css({
				height: fheight
			});
		},

		toggle: function (e) {
			e.preventDefault();

			if (this.formBox.hasClass('visible')) {
				this.hide();
			} else {
				this.show();
			}
		},

		show: function () {
			var height = this.formContent.outerHeight() + 40;
			var _this = this;

			this.formBox.addClass('visible animate');

			this.footer.animate({
				marginTop: -(125 + height),
			}, 500);
			this.delim.animate({
				height: 125 + height
			}, 500);
			this.formBox.animate({
				height: height
			}, 500, function () {
				_this.formBox.removeClass('animate');
				_this.formBox.find('a.close').show();
			});

			$('html, body').animate({
				scrollTop: $("footer .logo-footer").offset().top
			}, 510, 'easeOutQuad');
		},

		hide: function () {
			var _this = this;

			this.formBox.addClass('animate');

			this.footer.animate({
				marginTop: -(125),
			}, 500);
			this.delim.animate({
				height: 125
			}, 500);
			this.formBox.animate({
				height: 0
			}, 500, function () {
				_this.formBox.removeClass('animate');
			});

			this.formBox.removeClass('visible');
			this.formBox.find('a.close').hide();
		},


		finish: function (form) {
			form.find('.errors').remove();
			form.find('.error').remove();
			form.find('.error-input').removeClass('error-input');

			msg = $('<div class="send-message"><h2>DĚKUJEME</h2><p>Formulář byl úspěšně odeslán.</p></div>');
			msg.insertAfter(form);
			form.fadeOut(200, function () {
				msg.fadeIn(200);
			});
		}
	};


	Site.init();

})(window, document, jQuery);
